import React, { Component } from "react";

/// Link
import { Link } from "react-router-dom";

/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";

/// Menu
import MetisMenu from "metismenujs";

///
 import drump from "../../../images/card/drump.png";

class MM extends Component {
   componentDidMount() {
      this.$el = this.el;
      this.mm = new MetisMenu(this.$el);
   }
   componentWillUnmount() {
      // this.mm("dispose");
      // console.log(this.mm);
   }
   render() {
      return (
         <div className="mm-wrapper">
            <ul className="metismenu" ref={(el) => (this.el = el)}>
               {this.props.children}
            </ul>
         </div>
      );
   }
}

class SideBar extends Component {
   /// Open menu
   componentDidMount() {
      // sidebar open/close
      var btn = document.querySelector(".nav-control");
      var aaa = document.querySelector("#main-wrapper");

      function toggleFunc() {
         return aaa.classList.toggle("menu-toggle");
      }

      btn.addEventListener("click", toggleFunc);   
   }
   render() {
      /// Path
      let path = window.location.pathname;
      path = path.split("/");
      path = path[path.length - 1];

      /// Active menu
      let deshBoard = [
             "",
            // "workout-statistic",
            // "workout-plan",
            // "distance-map",
            // "diet-food-menu",
            // "personal-record",
         ],
         campaign = [
            "voucher",
         ];
       /*   app = [
            "app-profile",
            "post-details",
            "app-calender",
            "email-compose",
            "email-inbox",
            "email-read",
            "ecom-product-grid",
            "ecom-product-list",
            "ecom-product-list",
            "ecom-product-order",
            "ecom-checkout",
            "ecom-invoice",
            "ecom-customers",
            "post-details",
            "ecom-product-detail",
         ],
         email = ["email-compose", "email-inbox", "email-read"],
         shop = [
            "ecom-product-grid",
            "ecom-product-list",
            "ecom-product-list",
            "ecom-product-order",
            "ecom-checkout",
            "ecom-invoice",
            "ecom-customers",
            "ecom-product-detail",
         ],
         charts = [
            "chart-rechart",
            "chart-flot",
            "chart-chartjs",
            "chart-chartist",
            "chart-sparkline",
            "chart-apexchart",
         ],
         bootstrap = [
            "ui-accordion",
            "ui-badge",
            "ui-alert",
            "ui-button",
            "ui-modal",
            "ui-button-group",
            "ui-list-group",
            "ui-media-object",
            "ui-card",
            "ui-carousel",
            "ui-dropdown",
            "ui-popover",
            "ui-progressbar",
            "ui-tab",
            "ui-typography",
            "ui-pagination",
            "ui-grid",
         ],
         plugins = [
            "uc-select2",
            "uc-nestable",
            "uc-sweetalert",
            "uc-toastr",
            "uc-noui-slider",
            "map-jqvmap",
			"uc-lightgallery",
         ],
         widget = ["widget-basic"],
         forms = [
            "form-element",
            "form-wizard",
            "form-editor-summernote",
            "form-pickers",
            "form-validation-jquery",
         ],
         table = ["table-bootstrap-basic", "table-datatable-basic"],
         pages = [
            "page-registser",
            "page-login",
            "page-lock-screen",
            "page-error-400",
            "page-error-403",
            "page-error-404",
            "page-error-500",
            "page-error-503",
         ],
         error = [
            "page-error-400",
            "page-error-403",
            "page-error-404",
            "page-error-500",
            "page-error-503",
         ]; */
      return (
         <div className="deznav">
            <PerfectScrollbar className="deznav-scroll">
               <MM className="metismenu" id="menu">
                 


<li className={`${path === "" ? "mm-active" : ""}`}>
                     <Link
                        to="/"
                        className="ai-icon"
                        
                     >
                        
                        <i className="flaticon-381-home-2"></i>
                        <span className="nav-text">Dashboard</span>
                     </Link>
                  </li>


                  <li className={`${path === "device" ? "mm-active" : ""}`}>
                     <Link
                        to="/device"
                        className="ai-icon"
                        
                     >
                        
                        <i className="flaticon-381-smartphone-5"></i>
                        <span className="nav-text">Device</span>
                     </Link>
                  </li>

                  <li className={`${path === "auto-reply" ? "mm-active" : ""}`}>
                     <Link
                        to="/auto-reply"
                        className="ai-icon"
                     >
                        
                        <i className="flaticon-381-send"></i>
                        <span className="nav-text">Auto Reply</span>
                     </Link>
                  </li>

                  <li className={`${path === "welcome-template" ? "mm-active" : ""}`}>
                     <Link
                        to="/welcome-template"
                        className="ai-icon"
                     >
                        
                        <i className="flaticon-381-bookmark-1"></i>
                        <span className="nav-text">Welcome Template</span>
                     </Link>
                  </li>

                  
                  <li className={`${path === "templates" ? "mm-active" : ""}`}>
                     <Link
                        to="/templates"
                        className="ai-icon"
                     >
                        
                        <i className="flaticon-381-bookmark-1"></i>
                        <span className="nav-text">Templates</span>
                     </Link>
                  </li>

                 


                  <li className={`${path === "send-message" ? "mm-active" : ""}`}>
                     <Link
                        to="/send-message"
                        className="ai-icon"
                        
                     >
                        
                        <i className="flaticon-381-send-1"></i>
                        <span className="nav-text">Send Message</span>
                     </Link>
                  </li>


                  <li
                           className={`${
                              campaign.includes(path) ? "mm-active" : ""
                           }`}
                        >
                           <Link
                              className="ai-icon has-arrow"
                              to="#"
                              
                           >
                               <i className="flaticon-381-list-1"></i>
                        
                        <span className="nav-text">Report</span>
                              
                           </Link>
                           <ul
                              
                              className={`${
                                 campaign.includes(path) ? "mm-show" : ""
                              }`}
                           >
                              <li>
                                 <Link
                                    className={`${
                                       path === "messages"
                                          ? "mm-active"
                                          : ""
                                    }`}
                                    to="/messages"
                                 >
                                    Send Messages Report
                                 </Link>

                                 <Link
                                    className={`${
                                       path === "received-messages"
                                          ? "mm-active"
                                          : ""
                                    }`}
                                    to="/received-messages"
                                 >
                                    Received Messages Report
                                 </Link>

                                 <Link
                                    className={`${
                                       path === "api-messages"
                                          ? "mm-active"
                                          : ""
                                    }`}
                                    to="/api-messages"
                                 >
                                    API Messages Report
                                 </Link>

                              </li>
                            
                           </ul>
                        </li>


                 {/*  <li className={`${path === "messages" ? "mm-active" : ""}`}>
                     <Link
                        to="/messages"
                        className="ai-icon"
                     >
                        
                        <i className="flaticon-381-list-1"></i>
                        <span className="nav-text">Report</span>
                     </Link>
                  </li> */}


                {/*   <li className={`${path === "history" ? "mm-active" : ""}`}>
                     <Link
                        to="/history"
                        className="ai-icon"
                        
                     >
                        
                        <i className="flaticon-381-list-1"></i>
                        <span className="nav-text">Counter History</span>
                     </Link>
                  </li> */}

                 {/*  <li className={`${path === "wallet-history" ? "mm-active" : ""}`}>
                     <Link
                        to="/wallet-history"
                        className="ai-icon"
                        
                     >
                        
                        <i className="flaticon-381-percentage"></i>
                        <span className="nav-text">Wallet History</span>
                     </Link>
                  </li>
 */}

                 {/*  <li className={`${path === "packs" ? "mm-active" : ""}`}>
                     <Link
                        to="/packs"
                        className="ai-icon"
                        
                     >
                        
                        <i className="flaticon-381-briefcase"></i>
                        <span className="nav-text">Packs</span>
                     </Link>
                  </li> */}

<li className={`${path === "api-documentation" ? "mm-active" : ""}`}>
                     <Link
                        to="/api-documentation"
                        className="ai-icon"
                        
                     >
                        
                        <i className="flaticon-381-internet"></i>
                        <span className="nav-text">Api Documentation</span>
                     </Link>
                  </li>


                  <li className={`${path === "feedback" ? "mm-active" : ""}`}>
                     <Link
                        to="/feedback"
                        className="ai-icon"
                        
                     >
                        
                        <i className="flaticon-381-help-1"></i>
                        <span className="nav-text">Feedback</span>
                     </Link>
                  </li>

                 

               </MM>
              

               <div className="copyright">
                  <p>
                     <strong>{process.env.REACT_APP_NAME}</strong> ©All Rights Reserved
                  </p>
                  <p>By {process.env.REACT_APP_BY}</p>
               </div>
            </PerfectScrollbar>
         </div>
      );
   }
}

export default SideBar;
